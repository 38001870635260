import RootWrapper from "components/page/RootWrapper";
import { setCookie, getCookie } from "components/page/util/cookie";

const COOKIE_NAME = "session_start_page";
const COOKIE_EXPIRY_DAYS = 1;

export const wrapRootElement = RootWrapper;

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") return;

  const pagePath = `${location?.pathname ?? ""}${location?.search ?? ""}${location?.hash ?? ""}`;

  // Trigger Google Analytics page view event
  setTimeout(() => {
    if (typeof gtag === "function") {
      gtag("event", "page_view", { page_path: pagePath });
    }
  }, 100);

  // Set cookie on first page visit
  if (!getCookie(COOKIE_NAME)) {
    setCookie(COOKIE_NAME, location?.href ?? "", COOKIE_EXPIRY_DAYS);
    console.log(`First page visit tracked: ${location?.href ?? ""}`);
  }
};
