import { useEffect } from "react";

export default function ConsoleMessage() {
  // Console log easter egg
  useEffect(() => {
    function constructString({ text, padding }) {
      return `%c${" ".repeat(padding)}${text}${" ".repeat(padding)}`;
    }

    const linesConfig = [
      { text: "Hold up...", fontSize: 48 },
      { text: "Do you know what you're doing?", fontSize: 24 },
      {
        text: "If so, you should join our team!",
        fontSize: 16,
      },
      {
        text: "Apply at rhombus.com/careers/",
        fontSize: 14,
      },
      { text: "", fontSize: 6 },
    ];
    const largestFontSize = Math.max(
      ...linesConfig.map(({ fontSize }) => fontSize)
    );
    const longestLine = linesConfig.reduce((max, { text, fontSize }) => {
      const len =
        Math.ceil((text.length * fontSize) / (largestFontSize * 2)) *
        (largestFontSize * 2);
      if (len > max) {
        return len;
      }
      return max;
    }, 0);
    const lines = linesConfig.map(({ text, fontSize }) => {
      const len = text.length * fontSize;
      const unitsShort = longestLine - len;
      return {
        text,
        fontSize,
        len,
        padding: unitsShort / 2 / fontSize,
      };
    });
    // eslint-disable-next-line no-console
    console.log(
      constructString(lines[0]),
      `font-size: ${lines[0].fontSize}px; text-align: center; font-family: monospace; padding: 20px 0; font-weight: 300;  color: #fff; background: linear-gradient(to left, #00C1DE, #2A7DE1);`
    );
    // eslint-disable-next-line no-console
    console.log(
      constructString(lines[1]),
      `font-size: ${lines[1].fontSize}px; text-align: center; font-family: monospace; padding: 20px 0; font-weight: 300;  color: #fff; background: linear-gradient(to left, #00C1DE, #2A7DE1);`
    );
    // eslint-disable-next-line no-console
    console.log(
      constructString(lines[2]),
      `font-size: ${lines[2].fontSize}px; text-align: center; font-family: monospace; padding: 20px 0; font-weight: 700; font-weight: 300;  color: #fff; background: linear-gradient(to left, #00C1DE, #2A7DE1);`
    );
    // eslint-disable-next-line no-console
    console.log(
      constructString(lines[3]),
      `font-size: ${lines[3].fontSize}px; text-align: center; font-family: monospace; padding: 20px 0; font-weight: 700; font-weight: bold; color: #fff; background: linear-gradient(to left, #00C1DE, #2A7DE1);`
    );
    // eslint-disable-next-line no-console
    console.log(
      constructString(lines[4]),
      `font-size: ${lines[4].fontSize}px; text-align: center; font-family: monospace;  padding: 20px 0; font-weight: 300; color: #fff; background: linear-gradient(to left, #00C1DE, #2A7DE1);`
    );
  }, []);
  return null;
}
