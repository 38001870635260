import ConsoleMessage from "effects/ConsoleMessage";
import React from "react";

/**
 * This is the highest place in the React tree we can place components.
 * It is a good place to put a context provider.
 */
export default function RootWrapper({ element }) {
  return (
    <>
      <ConsoleMessage />
      {element}
    </>
  );
}
